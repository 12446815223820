.auction-page{
    
    .action-button-wrapper{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

}

.auction-live-page{
    .action-live-table{
        table tr:nth-child(2n) td {
            background-color: #999;
            }
        .action-buttons-wrapper{
            Button{
                margin-right: 5px;
            }
        }    
    }
}

/** Auction archive page*/
.auction-archive-page{

}

.counter-pop-wrapper{
    .ant-input-number{
        width: 100%;
    }
}

.rem-qouta-add-content{
    display: flex;
    justify-content: space-between;
}

.adjust-pop-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
