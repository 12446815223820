@mixin input-disabled {
    background-color: grey;
    border-color: grey;
}

.direct-offer-action-wrapper {
    display: flex;
    justify-content: space-between;
    width: 40px;
}

.direct-offer-popover {

    width: 350px;

    .desc-wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        img {
            width: 100px;
            border-radius: 4px;
        }

        .desc {
            margin: 0px 8px;

            .title {
                font-weight: bold;
                display: block;
                font-size: 16px;
            }
        }
    }

    .row-wrapper {
        padding: 0px 6px;
        margin-bottom: 4px;

        &.error {
            span {
                @extend input;
                background-color: rgb(243, 86, 86);
            }
        }

        label {
            width: 50%;
            display: inline-block;
            font-weight: bold;
        }

        input {
            width: 50%;
            display: inline-block;
            padding: 0px 4px;
        }

        span {
            @extend input;
            background-color: rgb(175, 174, 174);
        }
    }

}

.pop-over-buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;

    button {
        width: 120px;
        cursor: pointer;

        span {
            font-weight: bold;
            text-transform: uppercase;
        }


        &.buy {
            background-color: #01B04F;
            border-color: #01B04F;

            &[disabled] {
                @include input-disabled;
            }
        }

        &.add {
            background-color: #2F5597;
            border-color: #2F5597;

            &[disabled] {
                @include input-disabled;
            }
        }

        &.cancel {
            background-color: #FF0100;
            border-color: #FF0100;

            &[disabled] {
                @include input-disabled;
            }
        }
    }
}


.direct-sales-settings {
    .ant-form-item-label>label {
        text-transform: capitalize;
    }
}