.common-layout {

    &.ant-layout {
        background: #ffffff;
    }

    .content-wrapper {
        min-height: calc(100vh - 135px);
        background-color: #fff;
        padding: 15px 10px 10px 10px;
    }

    .ant-menu-horizontal {
        border-bottom: none;
    }

    .ant-layout-header {

        background: #ffffff;
        display: flex;

        .ant-menu {
            flex-basis: 85%;

            li.ant-menu-item-selected,
            li.ant-menu-submenu-selected,
            li:hover {
                border-bottom: transparent;
            }
        }

        .ant-menu-submenu-horizontal {
            .ant-menu-submenu-title {
                svg[class*="fa-"] {
                    margin-right: 10px;
                }
            }
        }

        .logo {
            width: 200px;
            flex-basis: 15%;
        }

        .logout-btn {
            border-color: transparent;
        }
    }

    .sub-header {

        display: flex;
        justify-content: space-between;

        .clock-wrapper {

            text-align: right;
            display: flex;
            align-content: space-around;
            flex-direction: column;
            justify-content: space-around;

            p {
                margin: 0px;

                &:first-child {
                    font-weight: bold;
                    font-size: 18px;
                }
            }
        }
    }
}