.activate-form-wrapper{
    
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .activate-form{
        width: 500px;
        background-color: white;
        padding: 40px;
        border-radius: 14px;

        .logo{
            width: 80%;
            margin-bottom: 10px;
        }

        .form-item{
            justify-content: center;
            align-items: center;

            &.center{
                text-align: center;
            }
            
            &.label{
                padding: 20px 0;
            }

            input{ 
                height: 50px; 
                font-size: 20px;
                border-radius: 4px;
                margin: 8px 0px 8px 0px;
            }

            label{
                font-size: 20px;
                font-weight: bold;
                color: rgb(87, 87, 87);
            }

            .input-icon{
                width: 50px;
                display: block;
            }

            .error-wrapper{ color: red; }

            .submit-btn{
                font-size: 18px;
                font-weight: bold;
                width: 200px;
                height: auto;
                margin-top: 20px;
                padding: 10px;
                border-radius: 5px;
                text-transform: uppercase;
                background-color: #18b9b3;
                color: #ffffff;
            }

        }

        

    }

    .error-login{
        color: red;
        margin-bottom: 0px;
    }

}

.ant-modal-body{
    text-align: center;
}