.action-button-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}


.action-text-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    a.ant-typography{
        display: block;
        margin: 2px 0px;
    }

    a.reject-link{
        color: red;
    }

    a.reject-link:hover{
        color: darkred;
    }
    
}

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 12px;
}