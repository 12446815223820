.bid-specs-input-wrapper{
    .list-wrapper{
        .list-item{
            padding: 2px 6px;
            margin: 0px 6px 0px;
            margin-left: -4px;
            // display: block;
            cursor: pointer;
            &::before{
                content: "\2022";
                margin-right: 4px;
            }
        }
    }
    
    .remove-info{
        font-size: 12px;
        color: #999;
        margin: 10px 0 0 0;
    }

    .list-input-wrapper{
        display: flex;
        justify-content: space-between;
    }
}