/** Common css */
.status-text {
  text-transform: capitalize;

  &.pending {
    color: rgb(223, 223, 63);
  }

  &.rejected,
  &.inactive {
    color: red;
  }

  &.accepted,
  &.active {
    color: green;
  }
}

.form-settings {
  .ant-row {
    &.ant-form-item {
      margin-bottom: 5px;
      max-width: 400px;

      .ant-form-item-label-left {
        min-width: 150px;
        background-color: #cacaca;
        padding-left: 8px;
        font-weight: bold;
      }

    }
  }
}

.time-picker-custom {
  select {
    height: 32px;
    width: 50px;
  }
}

.common-loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  // background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  background: rgb(22, 180, 177, 0.4);
}